import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom"
import Root, { Home, About, Services, Gallery, Contact, Error } from "./pages"
import axios from "axios"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
        loader: async () => {
          const response = await fetch(
            "/.netlify/functions/getInitialData"
          ).then((response) => response.json())
          // try {
          //   const data = await axios.get("/.netlify/functions/getInitialData")
          //   console.log(data.data)
          //   return data.data
          // } catch (error) {
          //   console.log("ERROR: ", error.response.data)
          // }
          return {
            status: 200,
            body: JSON.stringify(response),
          }
        },
      },
      {
        path: "services",
        element: <Services />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "contact",
        element: <Contact />,
      },
    ],
  },
])

const Routes = () => {
  return <RouterProvider router={router} />
}

export default Routes
