import React, { useState } from "react"
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs"
import { RxDotFilled, RxDot } from "react-icons/rx"

const images = [
    {
        url: "https://tailwindui.com/img/ecommerce-images/category-page-01-featured-collection.jpg"
    },
    {
        url: "https://tailwindui.com/img/ecommerce-images/category-page-01-featured-collection.jpg"
    },
    {
        url: "https://tailwindui.com/img/ecommerce-images/category-page-01-featured-collection.jpg"
    },
    {
        url: "https://tailwindui.com/img/ecommerce-images/category-page-01-featured-collection.jpg"
    },
    {
        url: "https://tailwindui.com/img/ecommerce-images/category-page-01-featured-collection.jpg"
    }
]

//for html tags
//const slides = [image, image, image]
//<Image src={slides[currentIndex]} alt="ImageDescription" width={YourSSWidth} height={YourSSHeight}/>
//<div className="hidden group-hover:flex justify-end py-2 overflow-x-auto">
const Carousel = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1
    setCurrentIndex(newIndex)
  }

  const nextSlide = () => {
    const isLastSlide = currentIndex === images.length - 1
    const newIndex = isLastSlide ? 0 : currentIndex + 1
    setCurrentIndex(newIndex)
  }

  const goToSlide = (imageIndex) => {
    setCurrentIndex(imageIndex)
  }

  return (
    <div className="h-[500px] w-full relative group">
      <div
        style={{ backgroundImage: `url(${images[currentIndex].url})` }}
        className="h-full bg-cover bg-center duration-500"
      >
      <div className="hidden group-hover:flex justify-center p-2 text-white cursor-pointer">
        {images.map((_, imageIndex) => (
          <div
            key={imageIndex}
            onClick={() => goToSlide(imageIndex)}
            className="text-2xl cursor-pointer"
          >
            {currentIndex === imageIndex ? <RxDotFilled /> : <RxDot />}
          </div>
        ))}
      </div>
      </div>
      <div className="hidden group-hover:block absolute top-[45%] -translate-x-0 translate-y-[45%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      <div className="hidden group-hover:block absolute top-[45%] -translate-x-0 translate-y-[45%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
    </div>
  )
}

export default Carousel
