import React from "react"
// import { Cloudinary } from "@cloudinary/url-gen";

const Gallery = () => {
  // const cld = new Cloudinary({ cloud: { cloudName: "davewur6k" } });
  // const cImage = cld.image('fine-linen-creation/hejasratmftfbho7qqhy').toURL()

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 mt-16">
      Gallery
      {/* <img
          className="aspect-[1/1.3] object-cover object-center rounded-lg shadow-md"
          src={cImage}
        /> */}
    </div>
  )
}

export default Gallery
