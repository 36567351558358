import React from "react"
import { Outlet } from "react-router-dom"
import Container, { Content, Header, Footer } from "features/layout"

const Root = () => {
  return (
    <Container>
      <Header />
      <Content>
        <main>
        <Outlet />
        </main>
      </Content>
      <Footer />
    </Container>
  )
}

export const loader = () => {
  console.log("root loader")
}

export default Root
