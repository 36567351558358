import React from "react"
import logo from 'assets/logoBoth.png'
import { Carousel } from "features/ui"

const Header = (props) => {
  return (
    <header>
      <div className="mx-auto flex items-center justify-center py-4 border-b border-gray-950">
        <img
          className="w-auto h-20"
          src={logo}
          alt="Formally 'A Party Place' Now 'Fine Linen Creation'"
        />
      </div>

      <nav
        className="mx-auto flex max-w-7xl items-center justify-between py-4 px-6 lg:px-8"
        aria-label="Global"
      >
        <div>Area 1</div>
        <div>Area 2</div>
        <div>Area 3</div>
      </nav>

      <Carousel />
    </header>
  )
}

export default Header
