import Root from "./root"
import About from "./about"
import Contact from "./contact"
import Error from "./error"
import Gallery from "./gallery"
import Home from "./home"
import Services from "./services"

export { About, Contact, Error, Gallery, Home, Services }

export default Root
